<template>
  <div class="payment-invoice grey lighten-1 pa-4 mt-6 rounded-lg">
    <div>
      <h3 v-if="showTitle" class="my-1 secondary--text text-uppercase">
        {{ $t("payment.details") }}
      </h3>

      <!-- <v-btn-toggle
        v-model="userType"
        borderless
        group
        mandatory
        color="primary"
      >
        <v-btn value="1">
          {{ $t("paymentInvoice.userPrivate") }}
        </v-btn>

        <v-btn value="2">
          {{ $t("paymentInvoice.userCompany") }}
        </v-btn>

        <v-btn value="3">
          {{ $t("paymentInvoice.owner") }}
        </v-btn>
      </v-btn-toggle> -->
      <v-form ref="form" v-model="valid" class="mt-4">
        <v-row>
          <v-col cols="12" v-if="userType == '2' || userType == '3'">
            <!-- Company -->
            <label v-html="`${$t('paymentInvoice.company')}` + '*'" />
            <v-text-field
              v-model="billingData.person.company"
              :rules="[requiredRules()]"
              :placeholder="`${$t('paymentInvoice.company')}`"
              hide-details="auto"
              solo
              @blur="validate()"
            />
          </v-col>
          <v-col
            cols="12"
            :sm="userType == '1' ? '12' : '6'"
            v-if="userType == '1' || userType == '3'"
          >
            <!-- Fiscal Code -->
            <label v-html="`${$t('paymentInvoice.fiscalCode')}` + '*'" />
            <v-text-field
              v-model="billingData.person.fiscalCode"
              :rules="fiscalCodeRules"
              :placeholder="`${$t('paymentInvoice.fiscalCode')}`"
              hide-details="auto"
              solo
              disabled
              @blur="validate()"
            />
          </v-col>
          <v-col cols="12" sm="6" v-if="userType == '2'">
            <!-- VAT Fiscal Code -->
            <label v-html="`${$t('paymentInvoice.fiscalCode')}`" />
            <v-text-field
              v-model="billingData.person.vatFiscalCode"
              :rules="vatFiscalCodeRules"
              :placeholder="`${$t('paymentInvoice.fiscalCode')}`"
              hide-details="auto"
              solo
              @blur="validate()"
            />
          </v-col>
          <v-col cols="12" sm="6" v-if="userType == '2' || userType == '3'">
            <!-- VAT Code -->
            <label v-html="`${$t('paymentInvoice.vatCode')}` + '*'" />
            <v-text-field
              v-model="billingData.person.vatCode"
              :rules="vatCodeRules"
              :placeholder="`${$t('paymentInvoice.vatCode')}`"
              hide-details="auto"
              solo
              @blur="validate()"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <!-- Postal Code -->
            <label v-html="`${$t('paymentInvoice.postalCode')}` + '*'" />
            <v-text-field
              v-model="billingData.billingAddress.postalcode"
              :rules="capRules"
              :placeholder="`${$t('paymentInvoice.postalCode')}`"
              hide-details="auto"
              solo
              @blur="validate()"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <!-- Province -->
            <label v-html="`${$t('paymentInvoice.province')}` + '*'" />
            <v-autocomplete
              v-model="billingData.billingAddress.province"
              :items="provinceList"
              :rules="[requiredRules()]"
              item-text="name"
              item-value="name"
              append-icon=""
              hide-details="auto"
              solo
              hide-no-data
              :placeholder="`${$t('paymentInvoice.province')}`"
              @blur="validate()"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4">
            <!-- City -->
            <label v-html="`${$t('paymentInvoice.city')}` + '*'" />
            <v-text-field
              v-model="billingData.billingAddress.city"
              :rules="[requiredRules()]"
              :placeholder="`${$t('paymentInvoice.city')}`"
              hide-details="auto"
              solo
              @blur="validate()"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <!-- Address 1 -->
            <label v-html="`${$t('paymentInvoice.address')}` + '*'" />
            <v-text-field
              v-model="billingData.billingAddress.address1"
              :rules="[requiredRules()]"
              :placeholder="`${$t('paymentInvoice.address')}`"
              hide-details="auto"
              solo
              @blur="validate()"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <!-- Address Number -->
            <label v-html="`${$t('paymentInvoice.addressNumber')}` + '*'" />
            <v-text-field
              v-model="billingData.billingAddress.addressNumber"
              :rules="[requiredRules()]"
              :placeholder="`${$t('paymentInvoice.addressNumber')}`"
              hide-details="auto"
              solo
              @blur="validate()"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <!-- PEC -->
            <label
              v-html="
                `${$t('paymentInvoice.pec')}` +
                  `${pecRequired ? '*' : ' (non obbligatorio)'}`
              "
            />
            <v-text-field
              v-model="billingData.person.emailCertified"
              :rules="pecRequired ? [requiredRules()] : []"
              :placeholder="`@` + `${$t('paymentInvoice.pec')}`"
              hide-details="auto"
              solo
              @blur="validate()"
            />
          </v-col>
          <v-col cols="12" sm="6" v-if="userType == '2' || userType == '3'">
            <!-- vat Sdi Code -->
            <label
              v-html="
                `${$t('paymentInvoice.vatSdiCode')}` +
                  `${vatSdiCodeRequired ? '*' : ''}`
              "
            />
            <v-text-field
              v-model="billingData.person.vatSdiCode"
              :rules="
                vatSdiCodeRequired
                  ? [requiredRules(), isSDICode()]
                  : [isSDICode()]
              "
              :placeholder="`${$t('paymentInvoice.vatSdiCode')}`"
              hide-details="auto"
              solo
              @blur="validate()"
            />
          </v-col>
        </v-row>
        <ResponseMessage class="mt-3" :response="responseBilling" />
        <div class="d-flex justify-end mt-4">
          <v-btn
            large
            color="primary"
            depressed
            :block="$vuetify.breakpoint.xs"
            @click="updateBillingDetails"
            :loading="savingBilling"
            >{{ $t("paymentInvoice.save") }}</v-btn
          >
        </div>
      </v-form>
    </div>
  </div>
</template>

<style lang="scss">
.payment-invoice {
  h3 {
    font-weight: 600;
    font-size: 24px;
  }
  label {
    font-weight: 600;
    text-transform: uppercase;
  }
  .v-input__slot {
    box-shadow: none !important;
  }
}
</style>

<script>
import RegistrationService from "~/service/userService";
import CustomService from "@/service/customService";
import ResponseMessage from "@/components/ResponseMessage.vue";

import { mapActions } from "vuex";
import { mapCartInfo } from "~/service/ebsn";

import { isCAP, requiredValue } from "~/validator/validationRules";
import { isFiscalCode, isPiva, isSDICode } from "@/customValidationRules";

export default {
  name: "PaymentInvoice",
  components: { ResponseMessage },
  props: {
    showTitle: { type: Boolean, default: true }
  },
  data() {
    return {
      valid: false,
      dirty: false,
      userType: "1",
      capRules: [requiredValue(), isCAP()],
      fiscalCodeRules: [requiredValue(), isFiscalCode()],
      vatFiscalCodeRules: [isPiva()],
      vatCodeRules: [requiredValue(), isPiva()],
      requiredRules: requiredValue,
      isSDICode: isSDICode,
      billingData: {
        person: {},
        billingAddress: {}
      },
      provinceList: [],
      invoiceOk: false,
      responseBilling: {},
      savingBilling: false
    };
  },
  computed: {
    ...mapCartInfo({ sellCondition: "accept_sell_condition" }),
    pecRequired() {
      return (
        (this.userType == "2" || this.userType == "3") &&
        !this.billingData.person.vatSdiCode
      );
    },
    vatSdiCodeRequired() {
      return (
        (this.userType == "2" || this.userType == "3") &&
        !this.billingData.person.emailCertified
      );
    }
  },
  methods: {
    ...mapActions({
      setCartInfo: "cart/setCartInfo"
    }),
    validate() {
      let _this = this;
      setTimeout(function() {
        if (_this.$refs.form) {
          _this.valid = _this.$refs.form.validate();
          _this.$emit("billingFormCompleted", _this.valid);
        }
      }, 200);
    },
    async updateBillingDetails() {
      this.savingBilling = true;
      try {
        if (this.$refs.form.validate()) {
          if (this.billingData.person) {
            this.billingData.person.personTypeId = this.userType;
          }
          await RegistrationService.updateBillingAddress(this.billingData).then(
            () => {
              this.invoiceOk = true;
              this.responseBilling = {};
            }
          );
          this.validate();
        }
      } catch (response) {
        this.responseBilling = response;
      } finally {
        this.savingBilling = false;
      }
    },
    async getUserDetail() {
      let res = await RegistrationService.getUserDetail();
      this.billingData = res;
      if (this.billingData.person && this.billingData.person.personTypeId) {
        this.userType = this.billingData.person.personTypeId;
        this.invoiceOk = true;
        this.responseBilling = {};
      }
      if (this.invoiceOk) {
        this.setCartInfo({ need_invoice: "true" });
      }
    },
    getProvinceList() {
      CustomService.provinceList(1, false).then(data => {
        this.provinceList = data;
      });
    }
  },
  async mounted() {
    this.getProvinceList();
    await this.getUserDetail();
    this.validate();
    if (typeof this.sellCondition === "undefined") {
      this.sellCondition = "false";
    }
  },
  watch: {
    userType() {
      this.validate();
    }
  }
};
</script>
